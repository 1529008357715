<template>
  <section class="no-padding-top">
    <div class="row">
      <div class="container-fluid formulario" ref="formulario">
        <div v-if="exibeSucesso" class="alert alert-success" role="alert">Registro salvo com sucesso.</div>
        <div v-if="exibeErro" class="alert alert-danger" role="alert">Ocorreu um erro ao salvar o registro.</div>
        <div class="block">
          <div class="row">
            <div class="col-lg-12">
              <div class="block-body">
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">CPF</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField17.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Associado</label>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField4.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Situação</label>
                  <div class="col-sm-2">
                    <select class="form-control" name="" id="" v-model="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASComboBox2.valorDoCampoField">
                      <option value="1">Ativos</option>
                      <option value="2">Aposentado</option>
                      <option value="3">Falecido</option>
                      <option value="4">Pensionista</option>
                      <option value="5">Cons. Fiscal</option>
                      <option value="6">Diretoria</option>
                      <option value="7">Exclusão</option>
                      <option value="8">Outro</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Unidade (UNED)</label>
                  <div class="col-sm-2">
                    <select class="form-control" name="" id="" v-model="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField1.valorDoCampoField">
                      <option value="maracana">Maracanã</option>
                      <option value="maria_da_graca">Maria da Graça</option>
                      <option value="nova_iguacu">Nova Iguaçu</option>
                      <option value="nova_friburgo">Nova Friburgo</option>
                      <option value="angra_dos_reis">Angra dos Reis</option>
                      <option value="petropolis">Petrópolis</option>
                      <option value="valenca">Valença</option>
                      <option value="itaguai">Itaguaí</option>
                    </select>
                    <!-- <input
                      type="text"
                      class="form-control"
                      v-model="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField1.valorDoCampoField"/> -->
                  </div>
                  <label class="col-sm-1 form-control-label"
                    >Data Cadastro</label
                  >
                  <div class="col-sm-2">
                    <input
                      type="date"
                      class="form-control"
                      :value="
                        dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField3.valorDoCampoField | converteData"
                      @input="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField3.valorDoCampoField = $event.target.value"
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Grupo</label>
                  <div class="col-sm-2">
                    <select class="form-control" name="" id="" v-model="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASComboBox1.valorDoCampoField">
                      <option value="00001">ASSISTENTE DE ADM</option>
                      <option value="00002">ASSISTENTE DE ALUNO</option>
                      <option value="00022">TEC. ADM. NIVEL AUX</option>
                      <option value="00023">PROFESSOR 3º GRAU</option>
                      <option value="00024">PEDAGOGO</option>
                      <option value="00025">PROFESSOR 2º GRAU</option>
                      <option value="00026">PROFESSOR EBTT</option>
                      <option value="00027">OUTROS</option>
                      <option value="00028">ECONOMISTA</option>
                      <option value="00029">PROFESSOR</option>
                      <option value="00030">TEC. DE LABORATORIO</option>
                      <option value="00031">NUTRICIONISTA</option>
                      <option value="00034">TEC NO LABOLATORIO</option>
                      <option value="00035">MOTORITA</option>
                      <option value="00036">ODONTOLOGIA</option>
                      <option value="00037">C. DENTISTA</option>
                      <option value="00038">PROFESSOR 1º GRAU</option>
                      <option value="00039">TECNICO SECRETARIADO</option>
                      <option value="00040">SECRETARIA EXECUTIVA</option>
                      <option value="00041">PENSIONISTA</option>
                      <option value="00042">REVISORA DE TEXTOS</option>
                      <option value="00043">TEC. DE EDIFICACOES</option>
                      <option value="00044">TEC. DE ASS. EDUC.</option>
                    </select>
                  </div>
                  <label class="col-sm-1 form-control-label">Admissão</label>
                  <div class="col-sm-2">
                    <input
                      type="date"
                      class="form-control"
                      :value="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField30.valorDoCampoField | converteData"
                      @input="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField30.valorDoCampoField = $event.target.value"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">SIAPE</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField29.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Dependentes</label>
                  <div class="col-sm-5">
                    <select class="form-control" name="" id="" @change="recuperaDadosDependente($event.target.value); exibeAssociado=false" v-model="dependenteSelecionado">
                      <option></option>
                      <option
                        v-for="dependente in dtoListaDependente
                          .listaDeParametros._CadastroDependentesForm_FASList1
                          .listaDeValores"
                        :key="dependente.idAssociado"
                        :value="dependente.idAssociado"
                      >
                        {{ dependente.guerra }}
                      </option>
                    </select>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary ml-3"
                    @click.prevent="recuperaListaDependentes"
                  >
                    Buscar Dependentes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="row">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary" @click.prevent="exibeAssociado=true">Associado</button>
              <button
                type="submit"
                class="btn btn-primary ml-3"
                @click.prevent="exibeAssociado=false"
              >
                Dependente
              </button>
            </div>
          </div>
        </div>
        <div class="block" v-show="exibeAssociado">
          <div class="row">
            <div class="col-lg-12">
              <div class="block-body">
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">CEP</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="dtoDadosEndereco.listaDeParametros._CadastroAssociadosForm_FASField36.valorDoCampoField" @keyup="buscaCep"/>
                  </div>
                  <label class="col-sm-1 form-control-label">Endereço</label>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosEndereco.listaDeParametros
                          ._CadastroAssociadosForm_FASField33.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Bairro</label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosEndereco.listaDeParametros
                          ._CadastroAssociadosForm_FASField34.valorDoCampoField
                      "
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Cidade</label>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosEndereco.listaDeParametros
                          ._CadastroAssociadosForm_FASField35.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Estado</label>
                  <div class="col-sm-2">
                    <select
                      class="form-control"
                      name=""
                      id=""
                      v-model="
                        dtoDadosEndereco.listaDeParametros
                          ._CadastroAssociadosForm_FASComboBox4
                          .valorDoCampoField
                      "
                    >
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                  <label class="col-sm-1 form-control-label">País</label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                      value="BRASIL"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Telefone</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField5.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Ramal</label>
                  <div class="col-sm-1">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField6.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Telefone</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField8.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Ramal</label>
                  <div class="col-sm-1">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField9.valorDoCampoField
                      "
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <!-- <label class="col-sm-1 form-control-label">Telefone</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField11.valorDoCampoField
                      "
                    />
                  </div> -->
                  <!-- <label class="col-sm-1 form-control-label">Ramal</label>
                  <div class="col-sm-1">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField12.valorDoCampoField
                      "
                    />
                  </div> -->
                  <label class="col-sm-1 form-control-label">Celular</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField14.valorDoCampoField
                      "
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">E-Mail</label>
                  <div class="col-sm-4">
                    <input
                      type="email"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField15.valorDoCampoField
                      "
                    />
                  </div>
                  <div class="i-checks">
                    <input
                      type="checkbox"
                      class="checkbox-template"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField16.valorDoCampoField
                      "
                    />
                    <label>Deseja receber informações por e-mail</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Data Nasc.</label>
                  <div class="col-sm-2">
                    <input
                      type="Date"
                      class="form-control"                    
                      :value="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField18.valorDoCampoField | converteData"
                      @input="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField18.valorDoCampoField = $event.target.value"
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Identidade</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField19.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Org.Expd</label>
                  <div class="col-sm-1">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField20.valorDoCampoField
                      "
                    />
                  </div>
                  <!-- <label class="col-sm-1 form-control-label">Emitido em</label>
                  <div class="col-sm-2">
                    <input
                      type="date"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField21.valorDoCampoField
                      "
                    />
                  </div> -->
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Doc. Prof.</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField22.valorDoCampoField
                      "
                    />
                  </div>
                  <!-- <label class="col-sm-1 form-control-label">Vence em</label>
                  <div class="col-sm-2">
                    <input
                      type="date"
                      class="form-control"
                      :value="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField23.valorDoCampoField | converteData
                      "
                      @input="dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField23.valorDoCampoField = $event.target.value"
                    />
                  </div> -->
                  <div class="i-checks">
                    <input
                      type="checkbox"
                      class="checkbox-template"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField24.valorDoCampoField
                      "
                    />
                    <label>Avisar por E-Mail</label>
                  </div>
                  <!-- <div class="i-checks col-sm-4">
                    <input
                      type="checkbox"
                      class="checkbox-template"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField25.valorDoCampoField
                      "
                    />
                    <label>Avisar por carta</label>
                  </div> -->
                </div>
                <!-- <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Pai</label>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField26.valorDoCampoField
                      "
                    />
                  </div>
                  <label class="col-sm-1 form-control-label">Mãe</label>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField27.valorDoCampoField
                      "
                    />
                  </div>
                </div> -->
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Cobrança</label>
                  <input
                    id="optionsRadios1"
                    type="radio"
                    checked=""
                    value="option1"
                    name="optionsRadios"
                  />
                  <label class="col-sm-2" for="optionsRadios1"
                    >Desconto em folha</label
                  >
                  <input
                    id="optionsRadios2"
                    type="radio"
                    checked=""
                    value="option1"
                    name="optionsRadios"
                  />
                  <label class="col-sm-2" for="optionsRadios1">Boleto</label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 form-control-label">Observações</label>
                  <div class="col-sm-9">
                    <textarea
                      type="text"
                      class="form-control"
                      v-model="
                        dtoDadosAssociado.listaDeParametros
                          ._CadastroAssociadosForm_FASField28.valorDoCampoField
                      "
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 form-control-label">Plano de saúde</label>
                  <div class="col-sm-5">
                    <select name="" id="" class="form-control" v-model="planoSaudeAssociado">
                      <option></option>
                      <option v-for="plano in listaPlanosSaude" :key="plano.idPlanoSaude" :value="plano.idPlanoSaude">{{ plano.descricao }}</option>
                      </select>
                  </div>
                  <button type="submit" class="btn btn-primary ml-3" @click="atualizaPlano">Atualizar Plano</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block" v-show="exibeAssociado">
          <div class="row">
            <div class="col-lg-12 d-flex flex-row-reverse">
              <button type="submit" class="btn btn-primary ml-3" @click="salvaAssociado">Salvar</button>
              <router-link
                to="/cadastro-associados"
                tag="button"
                class="btn btn-secondary ml-3"
                >Sair</router-link
              >
            </div>
          </div>
        </div>
        <div class="block" v-show="!exibeAssociado">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">Cadastro</label>
                <div class="col-sm-2">
                  <input type="date" class="form-control">
                </div>
                <label class="col-sm-1 form-control-label">Situação</label>
                <div class="col-sm-2">
                  <select name="" id="" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASComboBox1.valorDoCampoField">
                    <option value="1">Ativos</option>
                    <option value="2">Aposentado</option>
                    <option value="3">Falecido</option>
                    <option value="4">Pensionista</option>
                    <option value="5">Cons. Fiscal</option>
                    <option value="6">Diretoria</option>
                    <option value="7">Exclusão</option>
                    <option value="8">Falecido</option>
                  </select>
                </div>
                <label class="col-sm-1 form-control-label">Parentesco</label>
                <div class="col-sm-2">
                  <select name="" id="" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASComboBox2.valorDoCampoField">
                    <option value="ESP">Esposo(a)</option>
                    <option value="FIL">Filho(a)</option>
                    <option value="MAE">Mãe</option>
                    <option value="PAI">Pai</option>
                    <option value="COM">Companheiro(a)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">CPF</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField20.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Nome</label>
                <div class="col-sm-5">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField5.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Nascimento</label>
                <div class="col-sm-2">
                  <input type="date" class="form-control" :value="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField8.valorDoCampoField | converteData" @input="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField8.valorDoCampoField = $event.target.value">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">Identidade</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField21.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Org.Expd</label>
                <div class="col-sm-1">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField22.valorDoCampoField"/>
                </div>
                <!-- <label class="col-sm-1 form-control-label">Emitido em</label>
                <div class="col-sm-2">
                  <input type="date" class="form-control" :value="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField23.valorDoCampoField | converteData" @input="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField23.valorDoCampoField = $event.target.value">
                </div> -->
              </div>
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">Doc. Prof.</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField24.valorDoCampoField">
                </div>
                <!-- <label class="col-sm-1 form-control-label">Vencimento</label>
                <div class="col-sm-2">
                  <input type="date" class="form-control" :value="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField25.valorDoCampoField | converteData" @input="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField25.valorDoCampoField = $event.target.value">
                </div> -->
                <div class="i-checks">
                  <input type="checkbox" class="checkbox-template" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField26.valorDoCampoField">
                  <label>Avisar por E-Mail</label>
                </div>
                <!-- <div class="i-checks col-sm-4">
                  <input type="checkbox" class="checkbox-template" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField27.valorDoCampoField">
                  <label>Avisar por carta</label>
                </div> -->
              </div>
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">CEP</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField36.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Endereço</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField33.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Bairro</label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" v-model="dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField34.valorDoCampoField">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">Cidade</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField35.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Estado</label>
                <div class="col-sm-2">
                  <select name="" id="" class="form-control" v-model="dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASComboBox4.valorDoCampoField">
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>
                <label class="col-sm-1 form-control-label">País</label>
                <div class="col-sm-2">
                  <input type="text" disabled="disabled" class="form-control" v-model="dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField37.valorDoCampoField">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">Telefone</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField7.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Ramal</label>
                <div class="col-sm-1">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField9.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Telefone</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField11.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Ramal</label>
                <div class="col-sm-1">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField12.valorDoCampoField">
                </div>
              </div>
              <div class="form-group row">
                <!-- <label class="col-sm-1 form-control-label">Telefone</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField14.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Ramal</label>
                <div class="col-sm-1">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField15.valorDoCampoField">
                </div> -->
                <label class="col-sm-1 form-control-label">Celular</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField17.valorDoCampoField">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 form-control-label">E-Mail</label>
                <div class="col-sm-4">
                  <input type="email" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField18.valorDoCampoField">
                </div>
                <div class="i-checks">
                  <input type="checkbox" class="checkbox-template" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField19.valorDoCampoField">
                  <label>Deseja receber os informativos da ASSER?</label>
                </div>
              </div>
              <!-- <div class="form-group row">
                <label class="col-sm-1 form-control-label">Pai</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField28.valorDoCampoField">
                </div>
                <label class="col-sm-1 form-control-label">Mãe</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="dtoDependente.listaDeParametros._CadastroDependentesForm_FASField29.valorDoCampoField">
                </div>
              </div> -->
              <div class="form-group row">
                  <label class="col-sm-3 form-control-label">Plano de saúde</label>
                  <div class="col-sm-5">
                    <select name="" id="" class="form-control" v-model="planoSaudeDependente">
                      <option></option>
                      <option v-for="plano in listaPlanosSaude" :key="plano.idPlanoSaude" :value="plano.idPlanoSaude">{{ plano.descricao }}</option>
                      </select>
                  </div>
                  <button type="submit" class="btn btn-primary ml-3" @click="atualizaPlano">Atualizar Plano</button>
              </div>
            </div>
          </div>
        </div>
        <div class="block" v-show="!exibeAssociado">
          <div class="row">
            <div class="col-lg-12 d-flex flex-row-reverse">
              <button type="submit" class="btn btn-primary ml-3" @click="salvaDependente">Salvar</button>
              <router-link
                to="/cadastro-associados"
                tag="button"
                class="btn btn-secondary ml-3"
                >Sair</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      // Modelos em branco
      modelDtoDadosAssociado: {},
      modelDtoDadosEndereco: {},
      modelDtoDadosEnderecoDependente: {},
      modelDtoDependente: {},
      listaPlanosSaude: {},
      planoSaudeAssociado:"",
      planoSaudeDependente:"",
      dependenteSelecionado:"",

      // Exibe ou nao o box com os dados de dependente
      exibeAssociado: true,

      // Exibe ou nao a mensagem de sucesso e de erro
      exibeSucesso: false,
      exibeErro: false,

      dtoDadosAssociado: {
        banco: this.$store.state.nomeBD,
        tabela: "associado",
        idTabela: "idAssociado",
        listaDeParametros: {
          _CadastroAssociadosForm_FASField24: {
            nomeDoCampoField: "docAvisaVencEmail",
            valorDoCampoField: "0",
          },
          _CadastroAssociadosForm_FASField25: {
            nomeDoCampoField: "docAvisaVencCarta",
            valorDoCampoField: "0",
          },
          _CadastroAssociadosForm_FASField26: {
            nomeDoCampoField: "pai",
          },
          _CadastroAssociadosForm_FASField27: {
            nomeDoCampoField: "mae",
          },
          _CadastroAssociadosForm_FASField20: {
            nomeDoCampoField: "docOrgExp",
          },
          _CadastroAssociadosForm_FASField21: {
            nomeDoCampoField: "docDataExp",
            
          },
          _CadastroAssociadosForm_FASField22: {
            nomeDoCampoField: "docProfis",
          },
          _CadastroAssociadosForm_FASField23: {
            nomeDoCampoField: "docDataVencDocProfis",
            
          },
          // "_CadastroAssociadosForm_FASComboBox3#ComboBox": {
          //   banco: this.$store.state.nomeBD,
          //   idTabela: "idEmpresaAssociada",
          //   campoDescricao: "razaoSocial",
          //   sqlTemplate: "empresasassociadasparaassociados",
          //   listaDeParametros: [],
          // },
          _CadastroAssociadosForm_FASField1: {
            nomeDoCampoField: "uned",
          },
          _CadastroAssociadosForm_FASComboBox3: {
            nomeDoCampoField: "idEmpresaAssociada",
          },
          _CadastroAssociadosForm_FASComboBox1: {
            nomeDoCampoField: "idgrupos",
          },
          _CadastroAssociadosForm_FASComboBox2: {
            nomeDoCampoField: "idSituacoes",
          },
          _CadastroAssociadosForm_FASField8: {
            nomeDoCampoField: "tel2",
          },
          _CadastroAssociadosForm_FASField9: {
            nomeDoCampoField: "ramalTel2",
          },
          _CadastroAssociadosForm_FASField6: {
            nomeDoCampoField: "ramalTel1",
          },
          _CadastroAssociadosForm_FASField7: {
            nomeDoCampoField: "recadoTel1",
            valorDoCampoField: "0",
          },
          _CadastroAssociadosForm_FASField28: {
            nomeDoCampoField: "observacao",
          },
          _CadastroAssociadosForm_FASField4: {
            nomeDoCampoField: "nome",
          },
          _CadastroAssociadosForm_FASField29: {
            nomeDoCampoField: "matriculaEmpresaAssociada",
          },
          _CadastroAssociadosForm_FASField5: {
            nomeDoCampoField: "tel1",
          },
          _CadastroAssociadosForm_FASField2: {
            nomeDoCampoField: "guerra",
          },
          // "_CadastroAssociadosForm_FASComboBox1#ComboBox": {
          //   banco: this.$store.state.nomeBD,
          //   idTabela: "idgrupos",
          //   campoDescricao: "nomeGrupo",
          //   sqlTemplate: "consultagrupos",
          //   listaDeParametros: [],
          // },
          _CadastroAssociadosForm_FASField3: {
            nomeDoCampoField: "dataAdmissaoAssociacao",
            
          },
          _CadastroAssociadosForm_FASField13: {
            nomeDoCampoField: "recadoTel3",
            valorDoCampoField: "0",
          },
          _CadastroAssociadosForm_FASField14: {
            nomeDoCampoField: "celular",
          },
          // idAssociado: "2",
          idAssociado: {
            nomeDoCampoField: "idAssociado",
            valorDoCampoField: this.id.trim(),
          },
          _CadastroAssociadosForm_FASField15: {
            nomeDoCampoField: "eMail",
          },
          _CadastroAssociadosForm_FASField16: {
            nomeDoCampoField: "recebeNovidades",
            valorDoCampoField: "0",
          },
          _CadastroAssociadosForm_FASField31: {
            nomeDoCampoField: "tipoSang",
          },
          _CadastroAssociadosForm_FASField10: {
            nomeDoCampoField: "recadoTel2",
            valorDoCampoField: "0",
          },
          _CadastroAssociadosForm_FASField32: {
            nomeDoCampoField: "fatorRH",
          },
          _CadastroAssociadosForm_FASField11: {
            nomeDoCampoField: "tel3",
          },
          _CadastroAssociadosForm_FASField12: {
            nomeDoCampoField: "ramalTel3",
          },
          _CadastroAssociadosForm_FASField30: {
            nomeDoCampoField: "dataAdmissaoEmpresa",
            
          },
          // "_CadastroAssociadosForm_FASComboBox2#ComboBox": {
          //   banco: this.$store.state.nomeBD,
          //   idTabela: "idSituacoes",
          //   campoDescricao: "descricao",
          //   sqlTemplate: "consultasituacoes",
          //   listaDeParametros: [],
          // },
          _CadastroAssociadosForm_FASField17: {
            nomeDoCampoField: "docCPF",
          },
          _CadastroAssociadosForm_FASField18: {
            nomeDoCampoField: "dataNascimento",
            
          },
          _CadastroAssociadosForm_FASField19: {
            nomeDoCampoField: "docIdentidade",
          },
        },
      },
      dtoDadosEndereco: {
        banco: this.$store.state.nomeBD,
        tabela: "endereco",
        idTabela: "idAssociado",
        tabelaMaster: "associado",
        idTabelaMaster: "idAssociado",
        listaDeParametros: {
          _CadastroAssociadosForm_FASField35: {
            nomeDoCampoField: "cidade",
          },
          _CadastroAssociadosForm_FASField36: {
            nomeDoCampoField: "cep",
          },
          _CadastroAssociadosForm_FASField37: {
            nomeDoCampoField: "pais",
            valorDoCampoField: "BRASIL",
          },
          // idAssociado: "2",
          idAssociado: {
            nomeDoCampoField: "idAssociado",
            valorDoCampoField: this.id,
          },
          _CadastroAssociadosForm_FASComboBox4: {
            nomeDoCampoField: "estado",
            valorDoCampoField: "AC",
          },
          _CadastroAssociadosForm_FASField33: {
            nomeDoCampoField: "endereco",
          },
          _CadastroAssociadosForm_FASField34: {
            nomeDoCampoField: "bairro",
          },
        },
      },
      dtoListaDependente: {
        listaDeParametros: {
          _CadastroDependentesForm_FASList1: {
            listaDeValores: [],
          },
        },
      },
      dtoDependente: {
        banco: this.$store.state.nomeBD,
        tabela: "associado",
        idTabela: "idAssociado",
        listaDeParametros: {
          _CadastroDependentesForm_FASField5: {
            nomeDoCampoField: "guerra",
          },
          _CadastroDependentesForm_FASField4: {
            nomeDoCampoField: "matriculaAssociado",
          },
          _CadastroDependentesForm_FASField7: {
            nomeDoCampoField: "tel1",
          },
          _CadastroDependentesForm_FASField6: {
            nomeDoCampoField: "nome",
          },
          _CadastroDependentesForm_FASField1: {
            nomeDoCampoField: "idAssociadoPrincipal",
          },
          _CadastroDependentesForm_FASField3: {
            nomeDoCampoField: "dataAdmissaoAssociacao",
            
          },
          _CadastroDependentesForm_FASField2: {
            nomeDoCampoField: "idGrupos",
          },
          _CadastroDependentesForm_FASField19: {
            nomeDoCampoField: "recebeNovidades",
            valorDoCampoField: "0",
          },
          _CadastroDependentesForm_FASField15: {
            nomeDoCampoField: "ramalTel3",
          },
          _CadastroDependentesForm_FASField17: {
            nomeDoCampoField: "celular",
          },
          _CadastroDependentesForm_FASField18: {
            nomeDoCampoField: "eMail",
          },
          _CadastroDependentesForm_FASField11: {
            nomeDoCampoField: "tel2",
          },
          _CadastroDependentesForm_FASField12: {
            nomeDoCampoField: "ramalTel2",
          },
          _CadastroDependentesForm_FASField14: {
            nomeDoCampoField: "tel3",
          },
          _CadastroDependentesForm_FASField9: {
            nomeDoCampoField: "ramalTel1",
          },
          _CadastroDependentesForm_FASComboBox2: {
            nomeDoCampoField: "grauParentesco",
            valorDoCampoField: "ESP",
          },
          _CadastroDependentesForm_FASComboBox1: {
            nomeDoCampoField: "idSituacoes",
          },
          _CadastroDependentesForm_FASField8: {
            nomeDoCampoField: "dataNascimento",
            
          },
          idAssociado: {
            nomeDoCampoField: "idAssociado",
            valorDoCampoField: '',
          },
          _CadastroDependentesForm_FASField26: {
            nomeDoCampoField: "docAvisaVencEmail",
            valorDoCampoField: "0",
          },
          _CadastroDependentesForm_FASField27: {
            nomeDoCampoField: "docAvisaVencCarta",
            valorDoCampoField: "0",
          },
          _CadastroDependentesForm_FASField28: {
            nomeDoCampoField: "pai",
          },
          _CadastroDependentesForm_FASField29: {
            nomeDoCampoField: "mae",
          },
          _CadastroDependentesForm_FASField22: {
            nomeDoCampoField: "docOrgExp",
          },
          _CadastroDependentesForm_FASField23: {
            nomeDoCampoField: "docDataExp",
            
          },
          _CadastroDependentesForm_FASField24: {
            nomeDoCampoField: "docProfis",
          },
          _CadastroDependentesForm_FASField25: {
            nomeDoCampoField: "docDataVencDocProfis",
            
          },
          _CadastroDependentesForm_FASField20: {
            nomeDoCampoField: "docCPF",
          },
          _CadastroDependentesForm_FASField21: {
            nomeDoCampoField: "docIdentidade",
          },
        },
      },
      dtoDadosEnderecoDependente: {}
    }
  },
  beforeMount() {
    
  },
  mounted() {
    this.modelDtoDadosAssociado = this.dtoDadosAssociado
    this.modelDtoDadosEndereco = this.dtoDadosEndereco
    this.modelDtoDependente = this.dtoDependente
    this.modelDtoDadosEnderecoDependente = this.dtoDadosEndereco
    this.dtoDadosEnderecoDependente = this.dtoDadosEndereco
    this.recuperaDados(this.id)
    this.recuperaPlanoAssociado(this.id)
  },
  methods: {
    recuperaPlanoAssociado(idAssociado) {
      // this.$http.get('http://acmebrasil.com.br/GAS-Web/service/javaflex/recupera-lista-plano-associdado?idAssociado='+idAssociado,{  
      // })
      let parametros = {
        idAssociado: idAssociado
      }
      this.$http
      .post("recupera-lista-plano-associdado", parametros)
      .then((res)=> {
        this.listaPlanosSaude = res.data.listaPlanos
        this.planoSaudeAssociado = res.data.planoSaudeAssociado
        this.exibeAssociado ? this.planoSaudeAssociado = res.data.planoSaudeAssociado : this.planoSaudeDependente = res.data.planoSaudeAssociado
        console.log(res)
      })
    },
    atualizaPlano() {
      this.$store.state.loader = true
      let parametros = {
        idAssociado: this.id,
        idPlanoSaude: this.planoSaudeAssociado 
      }
      if(!this.exibeAssociado) {
        parametros = {
          idAssociado: this.dependenteSelecionado,
          idPlanoSaude: this.planoSaudeDependente
        }
      }
      this.$http
        .post("atualiza-plano-associado", parametros)
        .then((res) => {
          console.log(res)
          this.$store.state.loader = false
          this.exibeSucesso = true
          this.exibeErro = false
          this.$refs.formulario.scrollTop = 0
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.loader = false
          this.exibeSucesso = false
          this.exibeErro = true
          this.$refs.formulario.scrollTop = 0
        })

    },
    buscaCep() {
      if(event.target.value.length==8) {
        this.$store.state.loader = true
        this.$http.get('https://viacep.com.br/ws/'+event.target.value+'/json/',{
        }).then((res) => {
          if(this.exibeAssociado) {
            this.dtoDadosEndereco.listaDeParametros._CadastroAssociadosForm_FASField33.valorDoCampoField = res.data.logradouro
            this.dtoDadosEndereco.listaDeParametros._CadastroAssociadosForm_FASField34.valorDoCampoField = res.data.bairro
            this.dtoDadosEndereco.listaDeParametros._CadastroAssociadosForm_FASField35.valorDoCampoField = res.data.localidade
            this.dtoDadosEndereco.listaDeParametros._CadastroAssociadosForm_FASComboBox4.valorDoCampoField = res.data.uf
          } else {
            this.dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField33.valorDoCampoField = res.data.logradouro
            this.dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField34.valorDoCampoField = res.data.bairro
            this.dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASField35.valorDoCampoField = res.data.localidade
            this.dtoDadosEnderecoDependente.listaDeParametros._CadastroAssociadosForm_FASComboBox4.valorDoCampoField = res.data.uf
          }
          console.log(res)
        }).catch((err) => {
          console.log(err)
        })
      }
      this.$store.state.loader = false
    },
    recuperaDados(idAssociado) {
      // TODO: Verificar os campos combo que estao comentados
      this.modelDtoDadosAssociado.listaDeParametros.idAssociado.valorDoCampoField = idAssociado
      this.modelDtoDadosEndereco.listaDeParametros.idAssociado.valorDoCampoField = idAssociado
      this.$store.state.loader = true
      this.$http
        .post("executaJavaCadastro", {
          classe: "GenericoBusiness",
          metodo: "consultar",
          listaDeParametros: {
            listaDeParametros: {
              dtoDadosAssociado: this.modelDtoDadosAssociado,
              dtoDadosEndereco: this.modelDtoDadosEndereco,
            },
          },
        })
        .then((res) => {
          console.log(res)
          this.dtoDadosAssociado = res.data.listaDeParametros.dtoDadosAssociado
          this.dtoDadosEndereco = res.data.listaDeParametros.dtoDadosEndereco
          this.$store.state.loader = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.loader = false
        })
    },
    salvaAssociado() {
      let metodo = ''
      if(this.id!=' ') {      
        metodo='alterar'
      } else {
        metodo='incluir'
        delete(this.dtoDadosAssociado.listaDeParametros.idAssociado)
        delete(this.dtoDadosEndereco.listaDeParametros.idAssociado)
        // Diminuir o limite de caracteres do campo.
        this.dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField2.valorDoCampoField = this.dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField4.valorDoCampoField
        if(this.dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField2.valorDoCampoField.length>25) {
          this.dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField2.valorDoCampoField=this.dtoDadosAssociado.listaDeParametros._CadastroAssociadosForm_FASField2.valorDoCampoField.substring(0, 15)
        }
      }
      this.$store.state.loader = true
      this.$http
        .post("executaJavaCadastro", {
          classe: "GenericoBusiness",
          metodo: metodo,
          listaDeParametros: {
            listaDeParametros: {
              dtoDadosAssociado: {
                banco: this.$store.state.nomeBD,
                tabela: "associado",
                idTabela: "idAssociado",
                listaDeParametros: this.dtoDadosAssociado.listaDeParametros
              },
              dtoDadosEndereco: {
                banco: this.$store.state.nomeBD,
                tabela: "endereco",
                idTabela: "idAssociado",
                tabelaMaster: "associado",
                idTabelaMaster: "idAssociado",
                listaDeParametros: this.dtoDadosEndereco.listaDeParametros
              },
            },
          },
        })
        .then((res) => {
          console.log(res)
          this.$store.state.loader = false
          this.exibeSucesso = true
          this.exibeErro = false
          this.$refs.formulario.scrollTop = 0
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.loader = false
          this.exibeSucesso = false
          this.exibeErro = true
          this.$refs.formulario.scrollTop = 0
        })
    },
    recuperaListaDependentes() {
      this.$store.state.loader = true
      this.$http
        .post("executaJavaComParametro", {
          classe: "GenericoBusiness",
          metodo: "consultar",
          listaDeParametros: {
            listaDeParametros: {
              dtoListaDependente: {
                banco: this.$store.state.nomeBD,
                tabela: "associado",
                idTabela: "idAssociado",
                listaDeParametros: {
                  _CadastroDependentesForm_FASList1: {
                    banco: this.$store.state.nomeBD,
                    idTabela: "idAssociado",
                    campoDescricao: "guerra",
                    listaDeParametros: [
                      {
                        nomeDoParametro: "idAssociadoPrincipal",
                        valor: this.id,
                      },
                    ],
                    sqlTemplate: "sqlDependentesSimples",
                  },
                },
              },
            },
          },
        })
        .then((res) => {
          console.log(res)
          this.dtoListaDependente =
            res.data.listaDeParametros.dtoListaDependente
          this.$store.state.loader = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.loader = false
        })
    },
    recuperaDadosDependente(idDependente) {
      if(!idDependente) {return}
      this.$store.state.loader = true
      this.modelDtoDependente.listaDeParametros.idAssociado.valorDoCampoField = idDependente
      this.modelDtoDadosEnderecoDependente.listaDeParametros.idAssociado.valorDoCampoField = idDependente
      this.$http
        .post("executaJavaCadastro", {
          classe: "GenericoBusiness",
          metodo: "consultar",
          listaDeParametros: {
            listaDeParametros: {
              // _nomeBD: this.$store.state.nomeBD,
              dtoDependente: this.modelDtoDependente,
              dtoDadosEnderecoDependente: this.modelDtoDadosEnderecoDependente,
            },
          },
        })
        .then((res) => {
          console.log(res)
          this.dtoDependente = res.data.listaDeParametros.dtoDependente
          this.dtoDadosEnderecoDependente = res.data.listaDeParametros.dtoDadosEnderecoDependente
          this.recuperaPlanoAssociado(idDependente)
          this.$store.state.loader = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.loader = false
        })
    },
    salvaDependente() {
      let metodo = ''
      if(this.id) {      
        metodo='alterar'
      }
      this.$store.state.loader = true
      this.$http
        .post("executaJavaCadastro", {
          classe: "GenericoBusiness",
          metodo: metodo,
          listaDeParametros: {
            listaDeParametros: {
              dtoDependente: {
                banco: this.$store.state.nomeBD,
                tabela: "associado",
                idTabela: "idAssociado",
                listaDeParametros: this.dtoDependente.listaDeParametros
              },
              dtoDadosEndereco: {
                banco: this.$store.state.nomeBD,
                tabela: "endereco",
                idTabela: "idAssociado",
                tabelaMaster: "associado",
                idTabelaMaster: "idAssociado",
                listaDeParametros: this.dtoDadosEnderecoDependente.listaDeParametros
              },
            },
          },
        })
        .then((res) => {
          console.log(res)
          this.$store.state.loader = false
          this.exibeSucesso = true
          this.exibeErro = false
          this.$refs.formulario.scrollTop = 0
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.loader = false
          this.exibeSucesso = false
          this.exibeErro = true
          this.$refs.formulario.scrollTop = 0
        })
    },
  },
}
</script>

<style>
.formulario {
  overflow: scroll;
  height: 75vh;
}
::-webkit-scrollbar {
  height: 4px;
  width: 10px;
  border: 1px solid #343a40;
}
</style>